

















































































































import gloabls from "../gloabls";
import { Component, Vue } from "vue-property-decorator";
import translationsService from "../services/Translations";
import { Util } from "@/services/Util";
import SmtpSender from "@/services/SmtpSender";

@Component
export default class ContactSection extends Vue {
  g = gloabls;
  info = translationsService.getMessages().v.contact.info;

  // form
  firstName = "";
  email = "";
  subject = "";
  message = "";

  mounted() {
    translationsService.selsectedLanguage$.subscribe(() => {
      this.info = translationsService.getMessages().v.contact.info;
    });
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    if (Util.isBlank(this.email)) {
      Vue.$toast.error("Enter your email");
      return;
    }

    const message = {
      from: this.email,
      subject: `${this.subject} - ${this.firstName} | Wildbirds.studio`,
      body: this.message,
      name: this.email,
    };

    try {
      await SmtpSender.sendSmtp(message);
      this.email = "";
      this.firstName = "";
      this.subject = "";
      this.message = "";
      const thanks_message = this.$t('v.contact.form.thank_you')
      Vue.$toast.success(thanks_message.toString());
    } catch (e) {
      console.warn(e);
      await SmtpSender.openMailBox(message);
    }
  }

  callUs() {
    SmtpSender.openCallTo(gloabls.phone1);
  }
}
