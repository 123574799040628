









































































import { Component, Vue, Watch } from "vue-property-decorator";
import TranslationsService, {
  PortfolioProject,
} from "../services/Translations";
import Swiper from "swiper";
import { Util } from "../services/Util";
import { scrollto } from "../templateMain";
import { Subscription } from "rxjs";
import { interfaceService } from "@/services/Interface";

@Component
export default class CountsSection extends Vue {
  projects: PortfolioProject[] = [];
  selectedProject: PortfolioProject = null;
  swiper: Swiper;
  sub: Subscription;

  get projectId(): string {
    return this.$route.params.id;
  }

  mounted() {
    scrollto("#portfolio-details");
    this.sub?.unsubscribe();
    this.sub = TranslationsService.selsectedLanguage$.subscribe(() => {
      this.projects = TranslationsService.getMessages().v.portfolio.projects;
      this.loadProject();
    });
  }

  beforeUnmount() {
    this.sub?.unsubscribe();
    this.removeSlider();
  }

  @Watch("$route.params.id")
  async loadProject() {
    await this.removeSlider();
    this.selectedProject = this.projects.find((p) => p.id == this.projectId);
    await this.initSlider();
  }

  async removeSlider() {
    this.swiper?.destroy();
    this.swiper = null;
  }

  async initSlider() {
    await Util.wait(Util.getRandomInt(5, 30));
    if (this.swiper || !this.selectedProject) return;

    this.swiper = new Swiper(".portfolio-details-slider", {
      speed: 700,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
    await Util.wait(10);
    this.swiper.slideReset();
    console.log(this.projectId, this.selectedProject);
  }

  get projectCategories(): string {
    if (!this.selectedProject) return "-";
    return (
      this.selectedProject?.categories
        .map((cat) => this.$t(`v.portfolio.categories.${cat}`))
        .join(", ") ?? "-"
    );
  }

  toPortfolio() {
    interfaceService.toHomeSection("portfolio");
  }
}
