























import { Component, Vue, Watch } from "vue-property-decorator";
import { scrollto } from "../templateMain";

@Component({})
export default class Home extends Vue {
  mounted() {
    this.scroll();
  }

  @Watch('$route')
  scroll() {
    const section = this.$route.params.section;
    if (section && section.length > 1) {
      scrollto(`#${section}`);
    } else {
      scrollto(`#home`);
    }

    console.log(this.$route.params.section, "section");
  }
}
