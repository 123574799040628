




export default {
    pageName: "Wildbirds.studio",
    phone1: "+48 512818510",
    phone2: "",
    // TODO 
    email: "paweljastrzebski96@gmail.com",

    igor_linkedin: 'https://www.linkedin.com/in/igor-sowiński-379572153',
    pawel_linkedin: 'https://www.linkedin.com/in/jastrzebski-pawel',

    // SMTP
    // dev wildbirdsstudio-dev.web.app
    tokenPerDomain: '0c1b9196-1b0c-4856-842c-3a64f6626e68',
    // prod wildbirds.com
    // tokenPerDomain: '9f38e43a-5066-46d7-9108-afdd024a7f17',
    sendToEmail: "paweljastrzebski96@gmail.com",

    // clients section: location /public/clients/
    clients: [
        'soeko.png',
    ]
}