import VueI18n from 'vue-i18n'
import Vue from 'vue'
import {BehaviorSubject} from 'rxjs';
import gloabls from '@/gloabls';
Vue.use(VueI18n)

export type Lang = 'pl' | 'en';

export interface LangItem {
    code: Lang,
    name: string
}

export interface TeamMember {
    fullname: string;
    title: string,
    description: string,
    img: string,
    links: {
        facebook?: string,
        twitter?: string,
        linkedIn?: string,
        instagram?: string,
        webpage?: string
    }
}

export enum ProjectCategory {
    latest = "latest",
    app = "app",
    webstie = "webstie",
    graphics = "graphics",
}

export interface PortfolioProject {
    id: string,
    name: string,
    mainImage: string,
    shortDescription: string,
    categories: ProjectCategory[],
    pagerUrl?: string,
    // details
    detailsDescription: string,
    images: string[],
    customer: string,
    releaseDate: string,
}

export interface FAQItem {
    question: string;
    answer: string
}

export interface LanguageInterface {
    mainTitle: string,
    // views
    v: {
        hero: {
            header: string,
            subHeader: string,
            button: string
        },
        about: {
            intro: string,
            head: string,
            content: string,
            button: string
        },
        values: {
            intro: string,
            header: string,
            head_1: string,
            content_1: string,
            head_2: string,
            content_2: string,
            head_3: string,
            content_3: string,
        },
        features: {
            intro: string,
            header: string,
            points: string[],
            imp: {
                header: string
                points: {
                    header: string,
                    desc: string
                }[]
            }
        },
        services: {
            intro: string,
            header: string,
            list: {
                name: string,
                desc: string
            }[]
        },
        faq: {
            intro: string,
            header: string,
            questions1: FAQItem[]
            questions2: FAQItem[]
        },
        portfolio: {
            intro: string,
            header: string,
            projects: PortfolioProject[],
            go_to_page: string,
            more_details: string,
            project_information: string,
            details_categories: string,
            details_customer: string,
            details_realisation_date: string,
            details_project_url: string
            project_not_found: string
            categories: {
                [key in keyof typeof ProjectCategory]: string
            }
        },
        testimonials: {
            intro: string,
            header: string,
        },
        team: {
            intro: string,
            header: string,
            members: TeamMember[]
        },
        our_clients: {
            intro: string,
            header: string,
        },
        contact: {
            intro: string,
            header: string,
            info: {
                address: string,
                address_content: string,
                call_us: string,
                email_us: string,
                open_hours: string
                open_hours_contant: string
            },
            form: {
                your_name: string,
                your_email: string,
                subject: string,
                message: string,
                send_message: string,
                thank_you: string,
                loading: string
            }
        },
        footer: {
            description: string,
            headers: {
                useful_links: string,
                our_service: string,
                contact_us: string,
            },
            phone: string,
            email: string,
            all_rights_reserved: string,
            designed_by: string
        }
    },
    menu: {
        home: string,
        about: string,
        services: string,
        portfolio: string,
        team: string,
        contact: string,
        language: string,
        get_started: string,
        project_details: string
    }
}

const soekoImages = ['soeko/soeko_main.jpg', 'soeko/soeko_1.jpg', 'soeko/soeko_2.jpg', 'soeko/soeko_3.jpg'];
const moviememorizeImages = ['subtitles/subtitles_main.jpg', 'subtitles/subtitles_1.jpg', 'subtitles/subtitles_2.jpg'];

const pl: LanguageInterface = {
    mainTitle: 'Oprogramowanie dla twoich potrzeby',
    v: {
        hero: {
            header: "Nowoczesne rozwiązania programistyczne dla Twojej firmy",
            subHeader: "Od strony promocyjnej po aplikację mobilną",
            button: "Sprawdź naszą ofertę"
        },
        about: {
            intro: "KIM JESTEŚMY",
            head: 'Jesteśmy małym zespołem programistów, którzy za cel postawili sobie wcielanie pomysłów w życie.',
            content: 'Ostatnie dwa lata ciężkiej pracy przy tworzeniu różnych aplikacji dały nam ogromną wiedzę praktyczną. W ten sposób dowiedzieliśmy się wiele o najlepszych praktykach tworzenia wysokiej jakości oprogramowania, które jest niezawodne i łatwe w rozbudowie. Teraz nadszedł czas, aby podzielić się tą wiedzą i pomóc przedsiębiorcom w urzeczywistnianiu ich własnych pomysłów.',
            button: 'Dowiedz się więcej'
        },
        values: {
            intro: "NASZE WARTOŚCI",
            header: "O co w tym wszystkim chodzi?",
            head_1: "Efektywna komunikacja",
            content_1: "Kluczowy element każdej relacji. Dobre zrozumienie wymagań biznesowych to jedyny sposób na znalezienie najlepszych rozwiązań dostosowanych do Twoich potrzeb i budżetu.",
            head_2: 'Koncentracja na celu',
            content_2: "Od czasu do czasu warto spojrzeć z szerszej perspektywy. Cel długoterminowy jest równie ważny jak krótki sprint. Długofalowa perspektywa pomoże nam znaleźć najlepszą ścieżkę i narzędzia prowadzące do otwartych możliwości rozwoju w przyszłości.",
            head_3: 'Dobry plan',
            content_3: "Dobre zarządzanie czasem jest bardzo ważne. Każdy cel podzielony na mniejsze części jest bardzo pomocny w szacowaniu zadań i płynnym procesie realizacji oraz śledzeniu postępów.",
        },
        features: {
            intro: "FUNKCJE",
            header: "Co nas wyróżnia?",
            points: [
                'Gwarancja jakości',
                'Darmowa wycena',
                'Nowoczesne technologie',
                'Oprogramowanie wieloplatformowe',
                'Kompleksowe rozwiązania',
                'Okres nadzoru'
            ],
            imp: {
                header: "Cykl realizacji",
                points: [
                    {
                        header: "Zacznij od pomysłu",
                        desc: 'Prześlij nam swój pomysł. Jeśli Twój pomysł jest dość złożony, dołącz niezbędną dokumentację, w przeciwnym razie wystarczy prosty opis'
                    },
                    {
                        header: 'Weryfikacja zapytania',
                        desc: "Zapoznamy się z Twoim pomysłem, w przypadku wątpliwości z naszej strony skontaktujemy się z Tobą mailowo. W zależności od projektu może to zająć do 3 dni."
                    },
                    {
                        header: 'Nasza estymacja',
                        desc: "Po pomyślnej weryfikacji zapytania prześlemy przybliżone oszacowanie czasu wraz z zadaniami niezbędnymi do spełnienia wymagań specyfikacji i produktu końcowego."
                    },
                    {
                        header: "Podpisanie umowy",
                        desc: "Po zatwierdzeniu wyceny prześlemy do Państwa przygotowany kontakt i omówimy ewentualne wątpliwości, proces wdrożenia, a także przyszłe utrzymanie produktu."
                    },
                    {
                        header: "Realizacja",
                        desc: "Po pomyślnym podpisaniu umowy przystąpimy do realizacji projektu. Na etapie realizacji będziemy na bieżąco informować o postępach prac, dopytywać o szczegóły oraz rozwiązywać ewentualne problemy czy niejasności."
                    },
                    {
                        header: 'Wdrożenie i okres nadzoru',
                        desc: "Aby upewnić się, że produkt działa zgodnie z oczekiwaniami po wdrożeniu, będziemy go nadzorować przez określony czas, w zależności od stopnia złożoności projektu."
                    }
                ]
            }
        },
        services: {
            intro: 'Usługi',
            header: "Co możemy dla ciebie zrobić?",
            list: [
                {
                    name: "Aplikacje internetowe",
                    desc: "Aplikacje wykonane na zamówienie w oparciu o wymagania biznesowe. Łatwy dostęp do usług z całego świata poprzez przeglądarkę internetową."
                },
                {
                    name: "Aplikacje desktopowe",
                    desc: "Aplikacje desktopowe zapewniają przewagę szybkości nad aplikacjami internetowymi. Przydatne zwłaszcza w przypadku dużych aplikacji, gdzie czas ładowania aplikacji jest długi."
                },
                {
                    name: "Aplikacje mobilne",
                    desc: "Hybrydowe aplikacje mobilne zapewniają natywną szybkość z zaletą szybszego procesu development w porównaniu z aplikacjami natywnymi."
                },
                {
                    name: "Usługi outsourcingowe",
                    desc: "Jeśli potrzebujesz dodatkowej mocy w swoim zespole, pomożemy Ci w procesie rozwoju Twojej aplikacji w różnych obszarach. Skontaktuj się z nami, aby uzyskać więcej informacji."
                },
                {
                    name: "Projekty logo i grafiki",
                    desc: "Projekty logo i grafiki na potrzeby stron internetowych oraz aplikacji."
                },
                {
                    name: "Strony internetowe",
                    desc: "Strony wizytówkowe oparte na najnowszych frameworkach front-endowych."
                }
            ]
        },
        faq: {
            intro: 'F.A.Q',
            header: 'Często Zadawane Pytania',
            questions1: [
                {
                    question: 'Co z komunikacją i wprowadzaniem poprawek w codziennej pracy?',
                    answer: 'Aby zapewnić płynną komunikację i ciągłą informację zwrotną podczas developmentu, używamy tablicy Kanban. Pełny wgląd w rozwój uzyskasz dzięki prostemu widokowi tablicy, na którym znajdziesz informacje o zadaniach wykonywanych przez nasz zespół. Dodatkowo stosujemy podejście ciągłej integracji, dzięki czemu uzyskasz dostęp do nowych funkcjonalności, gdy tylko zostaną one ukończone przez zespół i wdrożone na naszym serwerze deweloperskim.'
                },
                {
                    question: "Jak długo zajmie twojemu zespołowi stworzenie aplikacji?",
                    answer: "To zależy od wielu czynników. Nawet przy bardzo precyzyjnej specyfikacji, gdy Twój projekt jest złożony, bardzo trudno jest przewidzieć krańcowe przypadki, które wymagają dodatkowego czasu na rozwiązanie. Dlatego zdecydowanie zalecamy podzielenie projektu na mniejsze moduły i określenie kolejności wykonywania. Wówczas oszacowanie czasu dla każdego modułu będzie znacznie prostsze i dokładniejsze. Takie podejście pomaga również w skalowaniu rozwoju poprzez dedykowane zespoły przypisane do pojedynczego modułu w przypadku, gdy czas developmentu jest kluczowym czynnikiem."
                },
            ],
            questions2: [
                {
                    question: 'Jak długo trwa stworzenie strony firmowej?',
                    answer: 'Okres realizacji zależy głównie od liczby sekcji i funkcjonalności, takich jak slidery obrazów, galerie, formularze kontaktowe i obsługa treści w wielu językach. Zwykle zajmuje to od jednego do trzech tygodni.'
                }
            ]
        },
        portfolio: {
            intro: 'PORTFOLIO',
            header: 'Sprawdź nasze najnowsze realizacje',
            projects: [
                {
                    id: "moviememorize",
                    name: "MovieMemorize",
                    categories: [ProjectCategory.latest, ProjectCategory.app],
                    mainImage: moviememorizeImages[0],
                    releaseDate: "2021",
                    shortDescription: "Platforma do nauki języków",
                    pagerUrl: "https://moviememorize.com",
                    detailsDescription: "Nasz osobisty projekt do nauki języków. Opracowany, aby pomóc w nauce języków w bardziej efektywny sposób poprzez oglądanie filmów. Dostępny na komputery, smartfony i telewizory",
                    images: moviememorizeImages,
                    customer: "MovieMemorize"
                },
                {
                    id: "soeko-landing-page",
                    name: "Soeko",
                    categories: [ProjectCategory.latest, ProjectCategory.webstie],
                    mainImage: soekoImages[0],
                    releaseDate: "2021",
                    shortDescription: "Strona docelowa",
                    pagerUrl: "https://www.szklo-hartowane.pl/",
                    detailsDescription: "Strona wykonana we frameworku Vue. Strona zawiera takie sekcje jak Start, Przegląd oferty, Wartości, Szybki kontakt, Media społecznościowe, Kontakt, Realizacje projektów i O nas. Strona została dostosowana do urządzeń mobilnych.",
                    images: soekoImages,
                    customer: "Soeko"
                }
            ],
            go_to_page: "Idź do strony",
            more_details: "Więcej szczegółów",
            project_information: "Informacje",
            details_categories: "Kategorie",
            details_customer: 'Klient',
            details_realisation_date: 'Data wykonania',
            details_project_url: 'Adres URL',
            project_not_found: "Przepraszamy, projekt nie został znaleziony",
            categories: {
                latest: "Najnowsze",
                app: "Aplikacja",
                graphics: "Grafika",
                webstie: "Strona internetowa"
            }
        },
        testimonials: {
            intro: 'OPINIE',
            header: 'Co mówią o nas nasi klienci',
        },
        team: {
            intro: 'zespół',
            header: 'Nasz ciężko pracujący zespół',
            members: [
                {
                    fullname: "Igor Sowiński",
                    title: "Fullstack Web & Mobile developer ",
                    description: "",
                    img: "IS1.png",
                    links: {
                        linkedIn: gloabls.igor_linkedin
                    }
                },
                {
                    fullname: "Paweł Jastrzębski",
                    title: "Fullstack Developer, UX/UI Designer",
                    description: "",
                    img: "PJ1.png",
                    links: {
                        linkedIn: gloabls.pawel_linkedin,
                        webpage: "https://jastrzebskipawel.com"
                    }
                }
            ]
        },
        our_clients: {
            intro: 'NASI KLIENCI',
            header: 'Zaufali nam',
        },
        contact: {
            intro: "KONTAKT",
            header: "Skontaktuj się z nami",
            info: {
                address: "Adres",
                address_content: "Pracujemy zdalnie",
                call_us: "Zadzwoń do nas",
                email_us: "Napisz do nas",
                open_hours: "Godziny otwarcia",
                open_hours_contant: "Od 8:00 do 16:00"
            },
            form: {
                your_name: 'Twoje imię',
                your_email: 'Twój email',
                subject: 'Temat',
                message: 'Wiadomość',
                send_message: "Wyślij wiadomość",
                thank_you: 'Twoja wiadomość została wysłana. Dziękujemy!',
                loading: 'Ładowanie'
            }
        },
        footer: {
            description: "Jeśli chcesz dowiedzieć się więcej, śledź nas w mediach społecznościowych. Dzielimy się tam informacjami o naszych najnowszych realizacjach.",
            headers: {
                useful_links: "PRZYDATNE LINKI",
                our_service: "NASZE USŁUGI",
                contact_us: "SKONTAKTUJ SIĘ Z NAMI",
            },
            phone: "Telefon",
            email: "Email",
            all_rights_reserved: "Wszelkie prawa zastrzeżone",
            designed_by: "Zaprojektowany przez"
        }
    },
    menu: {
        home: "Start",
        about: "O nas",
        services: "Usługi",
        portfolio: "Portfolio",
        team: "Zespół",
        contact: "Kontakt",
        language: "Język",
        get_started: "Rozpocznij",
        project_details: "Szczegóły Projektu"
    }
};

const en: LanguageInterface = {
    mainTitle: 'Software for your needs',
    v: {
        hero: {
            header: "Modern programming solutions for your business",
            subHeader: "From promotion website to mobile application",
            button: "Check our offer"
        },
        about: {
            intro: "WHO WE ARE",
            head: 'We are a small team of programmers who set themselves the goal of putting ideas into practice. ',
            content: 'The last two years of hard work by developing various applications gave us a huge amount of practice knowledge. Along this way, we learned a lot about the best practices of developing high-quality software that is reliable and easy to extend. Now the time has come to share this knowledge, and help entrepreneurs bring their own ideas into reality. ',
            button: 'Find out more'
        },
        values: {
            intro: "OUR VALUES",
            header: "What it is all about?",
            head_1: "Effective communication",
            content_1: "A key component of any relationship. A good understanding of business requirements is the only way to find the best solutions to suit your needs and budget.",
            head_2: 'Focus on the goal',
            content_2: "From time to time, it's worth taking a broader perspective. A long-term goal is as important as a short sprint. The long-term perspective will help us find the best path and tools leading to open development opportunities in the future.",
            head_3: 'Good plan',
            content_3: "Good time management is very important. Each goal broken down into smaller parts is very helpful in estimating tasks and a smooth implementation process as well as tracking progress.",
        },
        features: {
            intro: "FEATURES",
            header: "What makes us stand out?",
            points: [
                'Quality guarantee',
                'Free quote',
                'Modern technologies',
                'Cross-platform software',
                'Comprehensive solutions',
                'Supervision period'
            ],
            imp: {
                header: "Implementation cycle",
                points: [
                    {
                        header: "Start with an idea",
                        desc: 'Send us your idea. If your idea is quite complex, please attach the necessary documentation otherwise, a simple description is enough'
                    },
                    {
                        header: 'Query verification',
                        desc: "We will get acquainted with your idea, in case of doubts on our part, we will contact you by e-mail. Depending on the project, this may take up to 3 days."
                    },
                    {
                        header: 'Our estimation',
                        desc: "After successfully verifying your inquiry, we will send you a rough estimate of the time along with the tasks necessary to meet the specification and final product requirements."
                    },
                    {
                        header: "Signing a contract",
                        desc: "After the estimate is approved, we send you prepared contact and discuss possible doubts, implementation process, and future product maintenance."
                    }, {
                        header: 'Implementation',
                        desc: 'After successfully signing the contract, we will proceed with the project. At the implementation stage, we will keep you informed about the progress of work, ask for details and solve any problems or ambiguities.'
                    },
                    {
                        header: 'Implementation and supervision period',
                        desc: "To ensure that your product performs as expected after deployment, we will oversee the product for a certain amount of time depending on the complexity of the product."
                    }
                ]
            }
        },
        services: {
            intro: 'Services',
            header: "What can we do for you?",
            list: [
                {
                    name: "Web applications",
                    desc: "Custom-made applications based on business requirements. Easy access to services from around the world via a web browser."
                },
                {
                    name: "Desktop applications",
                    desc: "Desktop applications give you a speed advantage over web-based applications. Useful especially for large applications, when load time of application significant amount of time."
                },
                {
                    name: "Mobile applications",
                    desc: "Hybrid mobile applications provide native speed with the advantage of a faster development process compared to native applications."
                },
                {
                    name: "Outsourcing services",
                    desc: "If you need additional power in your team, we can help you with the development process of your application in various areas. Contact us for more information."
                },
                {
                    name: "Logo and graphics design",
                    desc: "Logo and graphic designs for websites and applications."
                },
                {
                    name: "Websites",
                    desc: "Business card pages based on the latest front-end frameworks."
                }
            ]
        },
        faq: {
            intro: 'F.A.Q',
            header: 'Frequently Asked Questions',
            questions1: [
                {
                    question: 'What about communicating and making corrections in your daily work? ',
                    answer: 'To ensure smooth communication and continuous feedback during development, we use a Kanban board. You will gain full insight into development thanks to a simple board view where you will find information about the tasks performed by our team. Additionally, we use a continuous integration approach, so you get access to new functionalities as soon as they are completed by the team and implemented on our development server.'
                },
                {
                    question: "How long will it take for your team to develop my application?",
                    answer: "That depends on many factors. Even with very precise specification, when your project is complex it is very hard to predict extreme cases which require additional development time to solve. So we strongly recommend splitting your project into smaller modules and specify an order of execution. Then time estimation for each module will be much simpler and more accurate. This approach helps also to scale development through dedicated teams assigned to a single module in the case when development time is a key factor."
                },
            ],
            questions2: [
                {
                    question: "How long does it take to create a business page?",
                    answer: "The implementation period mainly depends on the number of sections and functionality such as image sliders, galleries, contact forms, and multi-language content support. Usually, it takes from one to three weeks."
                }
            ]
        },
        portfolio: {
            intro: 'PORTFOLIO',
            header: 'Check out our latest projects',
            projects: [
                {
                    id: "moviememorize",
                    name: "MovieMemorize",
                    categories: [ProjectCategory.latest, ProjectCategory.app],
                    mainImage: moviememorizeImages[0],
                    releaseDate: "2021",
                    shortDescription: "Language learning platform",
                    pagerUrl: "https://moviememorize.com",
                    detailsDescription: "Our personal language learning project. Developed to help you learn languages more efficiently by watching movies. Available for desktops, mobile and TVs",
                    images: moviememorizeImages,
                    customer: "MovieMemorize"
                },
                {
                    id: "soeko-landing-page",
                    name: "Soeko",
                    categories: [ProjectCategory.latest, ProjectCategory.webstie],
                    mainImage: soekoImages[0],
                    releaseDate: "2021",
                    shortDescription: "Landing page",
                    pagerUrl: "https://www.szklo-hartowane.pl/",
                    detailsDescription: "Website made with Vue framework. The page contains sections such as Hero, Offer Overview, Values, Quick Contact, Social Media, Contact, Project Realizations, and About us. The website has been adapted to mobile devices.",
                    images: soekoImages,
                    customer: "Soeko"
                }
            ],
            go_to_page: "Go to page",
            more_details: "More details",
            project_information: "Information",
            details_categories: "Categories",
            details_customer: 'Customer',
            details_realisation_date: 'Execution date',
            details_project_url: 'Project URL',
            project_not_found: "Sorry, the project has not been found",
            categories: {
                latest: "Latest",
                app: "App",
                graphics: "Graphics",
                webstie: "Web page"
            }
        },
        testimonials: {
            intro: 'TESTIMONIALS',
            header: 'What our clients say about us',
        },
        team: {
            intro: 'TEAM',
            header: 'Our hard working team',
            members: [
                {
                    fullname: "Igor Sowiński",
                    title: "Fullstack Web & Mobile developer ",
                    description: "",
                    img: "IS1.png",
                    links: {
                        linkedIn: gloabls.igor_linkedin
                    }
                },
                {
                    fullname: "Paweł Jastrzębski",
                    title: "Fullstack Developer, UX/UI Designer",
                    description: "",
                    img: "PJ1.png",
                    links: {
                        linkedIn: gloabls.pawel_linkedin,
                        webpage: "https://jastrzebskipawel.com"
                    }
                }
            ]
        },
        our_clients: {
            intro: 'OUR CLIENTS',
            header: 'They trusted us',
        },
        contact: {
            intro: "CONTACT",
            header: "Contact Us",
            info: {
                address: "Address",
                address_content: "We work remotely",
                call_us: "Call Us",
                email_us: "Email Us",
                open_hours: "Open Hours",
                open_hours_contant: "From 8:00 a.m. to 4:00 p.m."
            },
            form: {
                your_name: 'Your Name',
                your_email: 'Your Email',
                subject: 'Subject',
                message: 'Message',
                send_message: "Send Message",
                thank_you: 'Your message has been sent. Thank you!',
                loading: 'Loading'
            }
        },
        footer: {
            description: "If you want to find out more, please follow us on social media. We share information about our latest projects.",
            headers: {
                useful_links: "USEFUL LINKS",
                our_service: "OUR SERVICES",
                contact_us: "CONTACT US",
            },
            phone: "Phone",
            email: "Email",
            all_rights_reserved: "All Rights Reserved",
            designed_by: "Designed by"
        }
    },
    menu: {
        home: "Home",
        about: "About",
        services: "Services",
        portfolio: "Portfolio",
        team: "Team",
        contact: "Contact",
        language: "Language",
        get_started: "Get Started",
        project_details: "Project details"
    },

};

export const messages = {
    pl: pl,
    en: en
}

export class TranslationsService {

    readonly selsectedLanguage$: BehaviorSubject<Lang | null> = new BehaviorSubject(null as any);

    readonly i18n: VueI18n | undefined
    readonly defaultLang: Lang = 'pl'
    readonly supportedLanugaes: Lang[] = ['pl', 'en']
    readonly CACHE_KEY = 'app_lang_code';

    public constructor() {
        const lang = this.getLang();
        this.i18n = new VueI18n({locale: lang, messages: (messages as any)});
        this.selsectedLanguage$.next(lang);
        console.log(this.getLang(), 'lang detected')
    }

    getMessages(): typeof messages.pl {
        const lang = this.selsectedLanguage$.getValue() ?? this.getLang();
        return messages[lang] ?? messages[this.defaultLang];
    }

    translate(key: string): string {
        return this.i18n?.t(key) as string
    }

    setLang(lang: Lang) {
        if (this.i18n) {
            this.i18n.locale = lang as any;
            this.selsectedLanguage$.next(lang);
            localStorage.setItem(this.CACHE_KEY, lang);
        }
    }

    getLang(): Lang {
        const cacheLang = localStorage.getItem(this.CACHE_KEY) as Lang;
        if (cacheLang) return cacheLang;

        let lang = navigator.language as Lang
        if (lang.includes('-')) {
            lang = lang.split('-')[0] as Lang
        }
        return this.supportedLanugaes.includes(lang) ? lang : this.defaultLang;
    }

}

export const translationsService = new TranslationsService();
export default translationsService

