


















































import TranslationsService, { TeamMember } from "@/services/Translations";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TeamSection extends Vue {

  members: TeamMember[] = [];

  //
  mounted() {
    TranslationsService.selsectedLanguage$.subscribe(() => {
      this.members = TranslationsService.getMessages().v.team.members;
    });
  }
}
