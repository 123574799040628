import router from '../router/index'
import {select, scrollto} from "../templateMain";

export class InterfaceService {

    toHomeSection(section: string) {
        const path = `/home/${section}`;
        const selector = `#${section}`;
        if (router.currentRoute.path !== path) {
            router.push({path: path});
        } else if (select(selector)) {
            scrollto(selector);
        }
    }

    to(path: string) {
        if (router.currentRoute.path !== path) {
            router.push({path: path});
        }
    }

    back() {
        router.back();
    }

}

export const interfaceService = new InterfaceService();